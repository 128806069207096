import styled from "styled-components"

// I'm centering elements vertically and horizontally.  height adjusts where the
// elements are located vertically.  100vh centers the elements in the window,
// but is too low because of the browser's toolbar and bookmarks bar.  Height
// also needs to be set because I'm using flexbox: flex won't work properly if
// there isn't any extra space.  Because I changed direction, justify-content
// affects vertical centering and align-items adjust horizontal centering.  I
// set text-align because I want to center heading text when the screen is
// narrow.
const Box = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
`

export default Box
