import styled from "styled-components"

const CustomLink = styled.a`
  font-size: large;
  font-family: var(--font-monospace);
  user-select: none;
  outline: none;
  text-decoration: none;
  padding: 10px 10px 10px;
  border-radius: 5px;

  &:link, &:visited {
    color: var(--accent-color);
  }

  &:hover, &:active {
    color: var(--bg-color);
    background: #CDFEAA;
  }
`

export default CustomLink
