/** This is the main landing page */

import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Box from "../components/box"
import CustomLink from "../components/customLink"

const querystring = require("querystring")

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`


let email = "mailto:contact@ethanensminger.com"
const emailDetails = querystring.stringify({ subject: "Saying Hi" })
email += "?" + emailDetails

const linkedin = "https://www.linkedin.com/in/ethan-ensminger/"

const github = "https://github.com/chemotaxis"

export default () => (
  <Box>
    <Helmet>
      <html lang="en"/>
      {/* I would add a meta charSet tag, but Gatsby seems to put one in
      automatically*/}
      <link rel="canonical" href="https://www.ethanensminger.com" />
      <meta name="description" content="Landing page for Ethan" />
      <title>Ethan Ensminger</title>
    </Helmet>
    <h1>Ethan Ensminger</h1>
    {/* fullWidth forces ButtonGroup to expand to the width of the parent
    element rather than basing width on content.*/}
    <LinkGroup>
      <CustomLink href={email}>Email</CustomLink>
      <CustomLink href={linkedin}>LinkedIn</CustomLink>
      <CustomLink href={github}>GitHub</CustomLink>
      <CustomLink href="/projects">
        Other Projects
      </CustomLink>
    </LinkGroup>
  </Box>
)
